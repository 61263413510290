<template>
  <div class="content">
    <PageHeader title="" />
    <div class="page-content container-fluid">
      <div class="row card-content-plan">
        <div class="col-12">
          <div class="card-content-title">
            <h1>Atualize agora seu plano!</h1>
            <h1>YupChat!</h1>
          </div>
          <div>
            <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 0 auto; margin-top: 30px; width:320px;">
              <div class="mini-tab-content">
                  <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                    <div class="mini-tab-flex">
                        <div style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                          <span>
                            Mensal
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </div>
                        <div style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                          <span>
                            Anual
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </div>
                    </div>
                    <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 20px; left: ${miniTab.indicator}px;`"></span>
                  </div>
              </div>
            </div>
          </div>
          <div>
            <span class="span-card-plan">Optando pelo plano anual você poderá economizar até X% esse ano.</span>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 p-0">
              <div class="content-plan-card relative">
                <div class="card-plan-header">
                  <span><strong>1.000</strong> Mensagens grátis</span>
                </div>
                <div class="card relative card-plan">
                  <div class="card-plan-title">
                    <h1>Light</h1>
                  </div>
                  <div class="card-plan-price">
                    <div class="card-plan-price-symbol to-left">R$</div>
                    <div class="card-plan-price-value to-left">
                      <h1>49,00</h1>
                    </div>
                    <div class="card-plan-price-month to-left">/Mes</div>
                    <div style="clear: both;"></div>
                    <div style="padding-left: 15px;">
                      R$ 588 /ano
                    </div>
                  </div>
                  <p class="card-plan-price-p">
                    Ideal para escalar atendimento e evoluir o contato com prospecção de leads e gestão de clientes e leads. Inicie o atendimento com o seu público
                  </p>
                  <div class="card-plan-price-button">
                    <button class="btn btn-yup-purple">Contratar</button>
                  </div>
                  <div class="card-plan-price-info-list">
                    <span>Principais beneficios do Light:</span>
                  </div>
                  <div class="card-plan-price-info-list-itens">
                    <ul>
                      <li>1 usuario incluído</li>
                      <li>Gestão de contatos</li>
                      <li>Fila de atendimento</li>
                      <li>Envio de arquivos</li>
                      <li>Multicontas</li>
                      <li>Todos canais</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 p-0">
              <div class="content-plan-card relative">
                <div class="card-plan-header">
                  <span><strong>2.000</strong> Mensagens grátis</span>
                </div>
                <div class="card relative card-plan">
                  <div class="card-plan-title">
                    <h1>Pro</h1>
                  </div>
                  <div class="card-plan-price">
                    <div class="card-plan-price-symbol to-left">R$</div>
                    <div class="card-plan-price-value to-left">
                      <h1>199,00</h1>
                    </div>
                    <div class="card-plan-price-month to-left">/Mes</div>
                    <div style="clear: both;"></div>
                    <div style="padding-left: 15px;">
                      R$ 588 /ano
                    </div>
                  </div>
                  <p class="card-plan-price-p">
                    Perfeito para quem já tem uma equipe e departamentos maiores e deseja campos personalizados. Personalize o atendimento e departamentos
                  </p>
                  <div class="card-plan-price-button">
                    <button class="btn btn-yup-purple">Contratar</button>
                  </div>
                  <div class="card-plan-price-info-list">
                    <span>Principais beneficios do Light:</span>
                  </div>
                  <div class="card-plan-price-info-list-itens">
                    <ul>
                      <li>'Light' mais...</li>
                      <li>Departamentos</li>
                      <li>Horário de funcionamento</li>
                      <li>Timeline do contato</li>
                      <li>Campos personalizados</li>
                      <li>Atalhos</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 p-0">
              <div class="content-plan-card relative">
                <div class="card-plan-header">
                  <span><strong>4.000</strong> Mensagens grátis</span>
                </div>
                <div class="card relative card-plan active">
                  <div class="card-plan-title">
                    <h1>Plus</h1>
                  </div>
                  <div class="card-plan-price">
                    <div class="card-plan-price-symbol to-left">R$</div>
                    <div class="card-plan-price-value to-left">
                      <h1>499,00</h1>
                    </div>
                    <div class="card-plan-price-month to-left">/Mes</div>
                    <div style="clear: both;"></div>
                    <div style="padding-left: 15px;">
                      R$ 588 /ano
                    </div>
                  </div>
                  <p class="card-plan-price-p">
                    Perfeito para alto volume de atendimento e deseja fluxos personalizados. Crie workflows e crie experiências únicas
                  </p>
                  <div class="card-plan-price-button">
                    <button class="btn btn-yup-purple">Contratar</button>
                  </div>
                  <div class="card-plan-price-info-list">
                    <span>Principais beneficios do Light:</span>
                  </div>
                  <div class="card-plan-price-info-list-itens">
                    <ul>
                      <li>1 usuario incluído</li>
                      <li>Gestão de contatos</li>
                      <li>Fila de atendimento</li>
                      <li>Envio de arquivos</li>
                      <li>Multicontas</li>
                      <li>Todos canais</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 p-0">
              <div class="content-plan-card relative">
                <div class="card-plan-header">
                  <span><strong>10.000</strong> Mensagens grátis</span>
                </div>
                <div class="card relative card-plan">
                  <div class="card-plan-title">
                    <h1>Enterprise</h1>
                  </div>
                  <div class="card-plan-price">
                    <div class="card-plan-price-symbol to-left">R$</div>
                    <div class="card-plan-price-value to-left">
                      <h1>1.189,00</h1>
                    </div>
                    <div class="card-plan-price-month to-left">/Mes</div>
                    <div style="clear: both;"></div>
                    <div style="padding-left: 15px;">
                      R$ 588 /ano
                    </div>
                  </div>
                  <p class="card-plan-price-p">
                    Perfeito para alto volume de atendimento e necessidade de dados aprofundados. Todas as tecnologias disponíveis na Plataforma Omni Business
                  </p>
                  <div class="card-plan-price-button">
                    <button class="btn btn-yup-purple">Contratar</button>
                  </div>
                  <div class="card-plan-price-info-list">
                    <span>Principais beneficios do Light:</span>
                  </div>
                  <div class="card-plan-price-info-list-itens">
                    <ul>
                      <li>1 usuario incluído</li>
                      <li>Gestão de contatos</li>
                      <li>Fila de atendimento</li>
                      <li>Envio de arquivos</li>
                      <li>Multicontas</li>
                      <li>Todos canais</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import { mask } from 'vue-the-mask';
import BillingService from '@/services/billing.service';
import Collapse from '@/directives/Collapse';
import PageHeader from '@/components/PageHeader.vue';

const { cnpj } = require('cpf-cnpj-validator');

export default {
  name: 'Billing',
  directives: {
    Collapse,
    mask,
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      cnpjValid: true,
      searching: false,
      isSending: false,
      invalidValue: false,
      invalidValueMessage: '',
      form: {
        tax_id: '',
        postal_code: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        province: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    searchAddress() {
      if (this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
    upgrade() {
      this.form.tax_id = this.form.tax_id.replace('.', '');
      this.form.tax_id = this.form.tax_id.replace('/', '');
      this.form.tax_id = this.form.tax_id.replace(' ', '');
      this.cnpjValid = cnpj.isValid(this.form.tax_id);
      if (this.cnpjValid) {
        this.invalidValue = false;
        const rawValue = this.form.value.replace(/[^0-9]/g, '') / 100;
        if (rawValue < 100) {
          this.invalidValue = true;
          this.invalidValueMessage = 'O valor deve ser maior do que R$ 100,00';
          return;
        }
        if (rawValue > 5000) {
          this.invalidValue = true;
          this.invalidValueMessage =
            'O valor deve ser menor do que R$ 5.000,00';
          return;
        }
        this.isSending = true;
        if (!this.invalidValue) {
          BillingService.upgradeAccount(this.form).then(
            () => {
              this.isSending = false;
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Conta atualizada',
                type: 'success',
              });
              this.$router.push('/invoices');
            },
            (error) => {
              this.isSending = false;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          );
        }
      }
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group .preloader {
  right: 20px;
  position: absolute;
  bottom: 20px;
}
.card-content-plan .card-content-title{
  text-align: center;
  font-size: 25px !important;
}
.card-content-plan .card-content-title h1{
  font-size: 25px !important;
}
.tab-elevation1 {
  border: solid #D6DADD 1px;
}
.tab-rounded {
border-radius: 20px;
}
.mini-tab-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}
.mini-tab-content {
  display: flex;
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
}
.mini-tab-root {
  color: rgba(0, 0, 0, 0.87);
}
.mini-tab-root {
  color: rgba(85, 85, 85, 0.87);
}
.mini-tab-fixed {
  width: 100%;
  overflow-x: hidden;
}
.mini-tab-scroller {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
.mini-tab-flex {
  display: flex;
}
.mini-tab-scroller {
  white-space: nowrap;
}

.mini-tab-b.mini-tab-selected i{
  color: rgb(255, 255, 255);
}
.mini-tab-b.mini-tab-selected span{
  color: rgb(255, 255, 255);
}
.mini-tab-b:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.mini-tab-button-root-color.mini-tab-selected {
  opacity: 1;
}
.mini-tab-b {
  z-index: 10;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: none;
}
.mini-tab-button-root-color {
  color: inherit;
  opacity: 0.7;
}
.mini-tab-button-root {
  min-width: 160px;
}
.mini-tab-button-root {
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
}
.mini-tab-button {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.mini-tab-indicator {
  background-color: #752DE6;
}
.mini-tab-indicator {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.span-card-plan {
  color: var(--gray-font-color);
  font-size: 25px;
  text-align: center;
  display: block;
  margin-top: 30px;
}
.card-plan-header {
  width: 188px;
  height: 54px;
  background-image: url('/assets/img/banner_header.png');
  background-size: contain;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 30px;
  color: white;
  font-size: 13px;
  padding: 10px;
}
.card-plan {
  min-height: 750px;
}
.card-plan.active {
  background: #EDE2FF;
}
.darkmode .card-plan.active {
  background: #521fa1;
}
.content-plan-card {
  min-height: 100px;
  padding: 12px;
  margin-top: 20px;
}
.card-plan-title {
  font-size: 25px;
  text-align: center;
  margin-top: 85px;
  margin-bottom: 40px;
}
.card-plan-title h1 {
  font-size: 25px !important;
}
.to-left {
  float: left !important;
}
.card-plan-price {
  margin: 0 auto;
}
.card-plan-price-p {
  padding: 35px;
  display: block;
  text-align: center;
  color: var(--gray-font-color-2) !important;
  margin-top: 0px;
  margin-bottom: 20px;
}
.card-plan-price-button {
  text-align: center;
  margin-bottom: 10px;
}
.card-plan-price-button button {
  border: none;
  background: #752DE6;
  color: #fff;
  padding: 10px;
  border-radius: 28px;
  padding-left: 30px;
  padding-right: 30px;
}
.card-plan-price-button button:hover {
  background: #7f38f0;
}
.card-plan-price-info-list span{
  color: var(--gray-font-color-2);
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-left: 40px;
  margin-top: 15px;
}
.card-plan-price-info-list-itens {
  margin-left: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card-plan-price-info-list-itens li::marker {
  color: #752DE6;
}
.p-0 {
  padding: 0px !important;
}
.card-plan-price-value h1 {
  font-size: 28px !important;
}
</style>
